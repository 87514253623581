import { sendGTMEvent } from '@next/third-parties/google';
import { getSession } from 'next-auth/react';
import { SG_EVENT_NAMES } from './eventNames';

// NOTE: we change tracking from segment to google tag manager and google analytics 4 ::: Sep 2024

export async function trackPageViewed(pageName, props) {
   sendGTMEvent({ event: SG_EVENT_NAMES.PAGE_VIEWED, value: pageName, ...props });
}

// new version of google analytics
export async function trackEvent(eventName, props) {
   try {
      const session = await getSession();

      if (!props && session) {
         props = {
            email: session?.user?.email,
            name: session?.user?.name,
            username: session?.user?.username,
         };
      }

      sendGTMEvent({
         event: eventName,
         event_label: eventName,
         event_category: 'app_event',
         ...props,
         event_callback: function () {},
      });

      // send conversion to Getreditus if user sign up
      if (eventName === SG_EVENT_NAMES.USER_SIGN_UP) {
         SendConversionToGetreditus(props.email);
      }
   } catch (error) {
      console.error('Error tracking event: ', error);
   }
}

export default async function trackEventServer(eventName, props) {
   // we couldn't use google tag manager in server side
   // so we use google analytics 4
}

export async function identifyUser(userId, props) {
   const session = await getSession();
   if (!userId) {
      userId = session?.user?.id;
   }

   if (!props) {
      props = {
         email: session?.user?.email,
         name: session?.user?.name,
         username: session?.user?.username,
         image: session?.user?.image,
      };
   }

   if (typeof window !== 'undefined') {
      // window.analytics.identify(userId, props);
      sendGTMEvent({ event: 'identify', value: { userId, ...props } });
   }
}

// :::::: Send conversion to Getreditus www.getreditus.com
// :::::: used for tracking affiliate conversion
function SendConversionToGetreditus(email) {
   // check window
   if (typeof window !== 'undefined') {
      window.gr('track', 'conversion', { email: email });
   }
}
